.search-bg{
    background-image: url(../Imagen/busqueda.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}
.products{
    background-image: url(../Imagen/cardmedic.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

a img {
    margin: 0 !important;
}

.alturito{
    min-height: 80vh;
}

@media (max-width: 1000px) {
    .search-bg{
        background-image: url(../Imagen/busqueda.png);
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
    }
}