.back{
    background-image: linear-gradient(180deg, rgba(186,204,176,1) 0%, rgba(134,153,88,1) 48%, rgba(64,78,30,1) 100%);
}
.paper{
    -webkit-box-shadow: 0px 0px 30px 0.5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 30px 0.5px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 30px 0.5px rgba(0,0,0,0.75);
}

.zoombottomsmap{
    color: #5f7230;
}
.zoombottomsmap:hover{
    color: #5f7230;
        -ms-transform: scale(1.07); /* IE 9 */
        -webkit-transform: scale(1.07); /* Safari 3-8 */
        transform: scale(1.07); 
        
}
.border-success{
    border-color: #5f7230 !important;
}

.zoombottoms{
    background-color: #5f7230;
    border-color: #5f7230;
}
.zoombottoms:hover{
    background-color: #5f7230;
    border-color: #5f7230;
        -ms-transform: scale(1.07); /* IE 9 */
        -webkit-transform: scale(1.07); /* Safari 3-8 */
        transform: scale(1.07); 
        
}
.zoombottoms:focus{
    background-color: #5f7230;
    border-color: #5f7230;
      
}



.wheretobuyplace{
    background-image: url("https://static.vecteezy.com/system/resources/previews/000/625/447/non_2x/vector-abstract-grey-geometric-background-with-triangles.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}