

.navcolor{
    background-color: #5f7230;
}
.hoverlink:hover{
    border-bottom: white 1px solid;
    margin: 0;
    
}


.font{
  font-size: large;
}


@media (max-width: 600px) {
  .phoneview {
    display: block;
    height: 95px;
    width: 100%;
  }
  .font{
    font-size: medium;
  }
}

@media (max-width: 425px) {
  .phoneview {
    display: none;
  }
  .font{
    font-size: medium;
  }
}
@media (max-width: 340px) {
  .phoneview {
    display: none;
  }
  .font{
    font-size: small;
  }
}