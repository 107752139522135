.cardfondo {
  background-color: #5f7230 solid;
}

.imgderm {
  border: 5px #ffffff solid;
  width: 240px;
  height: 120px;
}

@media (max-width:414px) {
  .imgderm {
    border: 5px #ffffff solid;
    width: 90vw;
    height: 20vh;
  }
}

.zoom {
  transition: transform .4s;
  -webkit-box-shadow: 0px 5px 23px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 23px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 23px 0px rgba(0, 0, 0, 0.75);
}

.zoom:hover {
  -ms-transform: scale(1.07);
  /* IE 9 */
  -webkit-transform: scale(1.07);
  /* Safari 3-8 */
  transform: scale(1.07);

}

.card-header-categoria {
  height: 35px;
  cursor: pointer;
}