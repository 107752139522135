.buttonsend{
    background-color: #5f7230;
}
.formulariocontacto{
    width: 75%;
}

.bordermap{
    border: 10px #95a871 solid;
}


@media (max-width: 600px) {
    .formulariocontacto{
        width: 100%;
    }
  }

