.back{
        background-image: linear-gradient(180deg, rgba(186,204,176,1) 0%, rgba(134,153,88,1) 48%, rgba(64,78,30,1) 100%);
        background-position: center;
        background-repeat: repeat;
        background-size: cover;
}

.empresa-card{
    display:flex;
    height: 100%;
}

.img-item{
    background-color:#5f7230;
}
