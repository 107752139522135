/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
    .border-phone{
        border: none !important;
    }
}

.background-gray{
    background-image: url(https://static.vecteezy.com/system/resources/previews/000/625/447/non_2x/vector-abstract-grey-geometric-background-with-triangles.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

body {
    background-image: linear-gradient(180deg, rgba(186,204,176,1) 0%, rgba(134,153,88,1) 48%, rgba(64,78,30,1) 100%);
}

.alturita {
    min-height: 80vh;
}